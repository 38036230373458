import { render, staticRenderFns } from "./applyDetail.vue?vue&type=template&id=2681d754&scoped=true"
import script from "./applyDetail.vue?vue&type=script&lang=js"
export * from "./applyDetail.vue?vue&type=script&lang=js"
import style0 from "./applyDetail.vue?vue&type=style&index=0&id=2681d754&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2681d754",
  null
  
)

export default component.exports